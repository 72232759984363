.container {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100%;
  padding-bottom: 32px;
  background-color: rgb(22, 22, 30);
}

.face {
  background-color: #000;
  height: 50vh;
  width: 50vh;
  max-width: 80%;
  border-radius: 10px;
  transition: all 300ms ease;
}

video {
  object-fit: cover;
}

.enroll {
  filter: blur(5px);
}

.loader {
  position: absolute;
  background: no-repeat url('../../assets/spinner.gif') center center;
  background-size: 100px;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.modal {
  background-color: rgb(12, 12, 12);
  outline: none;
  margin: auto;
  display: flex;
  align-items: center;
  width: 500px;
}

.modal-container {
  background-color: rgb(9, 9, 9);
  height: 100vh;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
  outline: none;
}

.modal-content {
  background-color: rgb(12, 12, 12);
  position: relative;
  height: 500px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  outline: none;
  border: 1px solid white;
  border-radius: 8px;
}

.modal-content p {
  /* background-color: #e5e5e5; */
  color: rgb(240, 240, 240);
  padding: 15px;
  border-radius: 8px;
}

.modal-content h3 {
  color: rgb(240, 240, 240);
  text-align: center;
}

.buttons {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.buttons img {
  height: 48px;
  width: 48px;
  margin: 0 10px;
  transition: all 100ms ease;
}

.buttons img:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.buttons img:active {
  transform: scale(1.05);
  cursor: pointer;
}

.overlay {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 4, 4, 0) !important;
  outline: none;
}

.enroll-text-info {
  color: #fff;
  width: 60vh;
  max-width: 80%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.enroll-text-info p {
  font-size: 2rem;
  text-align: center;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  .modal {
    width: 85vw;
  }

.modal-container {
  background-color: rgb(9, 9, 9);
  height: 100vh;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  outline: none;
}
  .modal-content {
    background-color: rgb(12, 12, 12);
    position: relative;
    height: 500px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    outline: none;
    border: 1px solid white;
    border-radius: 8px;
  }
}
