.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  background: #191919;
  width: 100%;

  img {
    height: 42.76px;
  }
}
