.progress-container {
  position: relative;
  display: flex;
  height: 10px !important;
  width: 300px;
  border-radius: 5px;
  border: 1px solid white;
}

.progress-container .progress-bar {
  height: 100%;
  background-color: #00cf70;
}
