.header-bar-container {
  background-color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  position: fixed;
  width: 100%;
  z-index: 1000;
}

a img.git-wiki {
  cursor: pointer;
  height: 27px;
  margin-right: 16px;
}

.logoImg {
  height: 30px;
  margin: 0 50px 0 20px;
  cursor: pointer;
}

@media (max-width: 600px) {
  .logoImg {
    margin-left: 0;
    margin-right: 30px;
  }  
}

.face-hint {
  background-color: #000;
  height: 50vh;
  width: 50vh;
  max-width: 80%;
  border-radius: 10px;
  transition: all 300ms ease;
}

video {
  object-fit: cover;
}

.modal {
  position: relative;
  width: 400px;
  background-color: rgb(12, 12, 12);
  color: rgb(240, 240, 240);
  border-radius: 8px;
  z-index: 2000;
}

.overlay {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 4, 4, 0) !important;
}
